var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.is_mounted
    ? _c("div", { attrs: { align: "center" } }, [
        _c("div", { staticClass: "text-h4" }, [_vm._v("Carica i Simpli")]),
        _c("br"),
        _c("strong", [
          _vm._v("Scarica i Simpli da allegare alla quietanza selezionata"),
        ]),
        _c("br"),
        _c("hr"),
        _c("br"),
        _c("br"),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.is_loading,
                expression: "!is_loading",
              },
            ],
            staticClass:
              "text-center justify-center q-pa-md q-gutter-sm fixed-center",
            staticStyle: { "z-index": "1000" },
          },
          [_c("q-spinner-hourglass", { attrs: { size: "4em" } })],
          1
        ),
        _c("div", { staticClass: "row justify-center" }, [
          _c(
            "div",
            {
              staticClass: "col-12 col-md-5 q-pr-md",
              attrs: { align: "left" },
            },
            [
              _c("h5", { staticClass: "q-mt-sm q-mb-sm" }, [
                _vm._v("Dati generali pratica"),
              ]),
              _c(
                "q-list",
                { attrs: { separator: "" } },
                [
                  _c(
                    "q-item",
                    [
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", [_vm._v("Cliente:")]),
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v(
                              _vm._s(_vm.dati_pratica.nominativo.toUpperCase())
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", [_vm._v("Stato della pratica:")]),
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v(_vm._s(_vm.dati_pratica.stato_pratica)),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "q-item",
                    [
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", [_vm._v("Numero interno:")]),
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v(_vm._s(_vm.dati_pratica.numero_pratica)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", [_vm._v("Numero pratica:")]),
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v(_vm._s(_vm.dati_pratica.numero)),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "q-item",
                    [
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", [
                            _vm._v("Unità operativa richiedente:"),
                          ]),
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v(_vm._s(_vm.dati_pratica.unita_operativa)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", [_vm._v("Persona operativa:")]),
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v(_vm._s(_vm.dati_pratica.operatore)),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("br"),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-12 col-md-5" },
            [
              _c("q-input", {
                attrs: {
                  label: "Note su caricamento file",
                  filled: "",
                  type: "textarea",
                },
                model: {
                  value: _vm.note_upload,
                  callback: function ($$v) {
                    _vm.note_upload = $$v
                  },
                  expression: "note_upload",
                },
              }),
              _c("br"),
              _c("br"),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-12 col-md-10", attrs: { align: "center" } },
            [
              _c("q-uploader", {
                ref: "UploadObject",
                staticStyle: {
                  "max-width": "800px",
                  width: "100%",
                  "min-height": "300px",
                  "box-shadow":
                    "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                },
                attrs: {
                  url: "https://localhost:1000",
                  color: "teal",
                  label: "Trascina QUI i PDF dei SIMPLI",
                  multiple: "",
                  "hide-upload-btn": "",
                  name: "upload_simpli",
                  filter: _vm.checkFileType,
                },
                on: { rejected: _vm.onRejected, uploading: _vm.onUploading },
              }),
            ],
            1
          ),
        ]),
        _c("br"),
        _c("hr"),
        _c("div", { staticClass: "row", attrs: { align: "center" } }, [
          _c(
            "div",
            { staticClass: "col-12 col-md-6", attrs: { align: "center" } },
            [
              _c("QQButton", {
                attrs: {
                  label: "INDIETRO",
                  color: "blue-grey",
                  icon: "undo",
                  size: "md",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onIndietroClicked.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-12 col-md-6", attrs: { align: "center" } },
            [
              _c("QQButton", {
                attrs: {
                  label: "CARICA SIMPLI",
                  color: "blue-grey",
                  icon: "mdi-arrow-right-bold",
                  size: "md",
                  disable: _vm.pulsante_disattivato,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onUploading.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
        ]),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("br"),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }